import styles from './NotFound.module.css';

export const NotFound = () => {
   return (
      <div className={styles.container}>
         <h1>404: FOOD NOT FOUND</h1>
      </div>
   )
};

